import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

import { ActionButton, ActionScrollLink } from "../components/actionButton";
import MainLayout from "../components/layout/main";
import * as PageComponent from "../components/pageComponents/_index";
import * as Typo from "../components/typo";
import circleDownArrowIcon from "../images/icons/circle-arrow-down.svg";

// MARK: Page

interface IndexPageProps {
  data: {
    caseStudyFollowTheLights: any;
    caseStudyIChing: any;
    caseStudyThumbnails: any;
    caseStudyContents: any;
  };
}

export default class IndexPage extends React.Component<IndexPageProps> {
  constructor(props: IndexPageProps) {
    super(props);

    this.copyEmailToClipboard = this.copyEmailToClipboard.bind(this);
  }

  public copyEmailToClipboard() {
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = "hello@oroboro.studio";
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);
  }

  public render() {
    const { data } = this.props;
    const caseStudyThumbnails = data.caseStudyThumbnails.edges.map(
      (edge: any) => edge.node
    );
    const caseStudyContents = data.caseStudyContents.edges.map(
      (edge: any) => edge.node
    );

    const onClickCopyEmail = () => this.copyEmailToClipboard();

    return (
      <MainLayout>
        {
          // MARK : –– Head
        }
        <Helmet>
          <title>
            Oroboro | A startup studio specialising in product engineering
          </title>
        </Helmet>

        {
          // MARK : –– Hero section
        }
        <PageComponent.HeroSection>
          <PageComponent.HeroSectionContent>
            <PageComponent.HeroTitle data-aos="fade-up">
              We collaborate with founders
              {/* tslint:disable-next-line:jsx-self-close */}
              <PageComponent.HeroTitleSpace> </PageComponent.HeroTitleSpace>
              <PageComponent.HeroTitleBreak />
              <Typo.Greyed>to take their idea from 0 to 1</Typo.Greyed>
            </PageComponent.HeroTitle>
            <PageComponent.HeroCTAButtonContainer
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <ActionScrollLink
                displaySettings={{
                  title: "See our work",
                  icon: circleDownArrowIcon,
                  customTitleStyles: PageComponent.heroActionTitleStyle,
                  customIconStyles: PageComponent.heroActionIconStyle
                }}
                scrollSettings={{
                  to: "case-studies",
                  smooth: true,
                  spy: true,
                  hashSpy: true,
                  offset: -40
                }}
              />
            </PageComponent.HeroCTAButtonContainer>
          </PageComponent.HeroSectionContent>
        </PageComponent.HeroSection>

        {
          // MARK : –– About section
        }
        <PageComponent.AboutSection id="about" data-aos="fade-up">
          <PageComponent.AboutHeading>
            _ We specialise in product engineering:
            <br />
            <Typo.Greyed>
              building quality, scaleable software with founders’ goals in mind
            </Typo.Greyed>
          </PageComponent.AboutHeading>
          <PageComponent.AboutBodyContainer>
            <PageComponent.AboutBody>
              Launching a startup is hard. From transforming an idea into great
              design, to engineering a robust product, to hiring the right team
              — the chaotic quest of building a startup can be daunting at
              first.
              <br />
              <br />
              At Oroboro, we collaborate intensively with founders as co-pilots,
              providing structure to the chaos by understanding what it takes to
              create the product, sketching out a game plan and building it step
              by step.
            </PageComponent.AboutBody>
            <PageComponent.AboutCTAButtonContainer>
              <ActionScrollLink
                displaySettings={{
                  title: "Contact us"
                }}
                scrollSettings={{
                  to: "contact",
                  smooth: true,
                  spy: true,
                  hashSpy: true
                }}
              />
            </PageComponent.AboutCTAButtonContainer>
          </PageComponent.AboutBodyContainer>
        </PageComponent.AboutSection>

        {
          // MARK : –– Case studies section
        }
        <PageComponent.CaseStudiesSection id="case-studies">
          <Typo.H2 data-aos="fade-right">
            _ Our case studies—
            <br />
            <Typo.Greyed>reflections on exciting past projects</Typo.Greyed>
          </Typo.H2>
          <PageComponent.CaseStudiesContainer
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {this.renderCaseStudies(caseStudyThumbnails, caseStudyContents)}
          </PageComponent.CaseStudiesContainer>
        </PageComponent.CaseStudiesSection>

        {
          // MARK : –– Contact section
        }
        <PageComponent.ContactSection id="contact">
          <PageComponent.ContactSectionContent>
            <Typo.H2 data-aos="fade-right">
              Let’s get in touch
              <br />
              <Typo.Greyed>
                Email us at{" "}
                <PageComponent.ContactEmail>
                  hello@oroboro.studio
                </PageComponent.ContactEmail>
              </Typo.Greyed>
            </Typo.H2>
            <PageComponent.ContactCTAButtonContainer
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <ActionButton
                displaySettings={{
                  title: "Copy email address"
                }}
                onClick={onClickCopyEmail}
              />
            </PageComponent.ContactCTAButtonContainer>
          </PageComponent.ContactSectionContent>
        </PageComponent.ContactSection>
      </MainLayout>
    );
  }

  private renderCaseStudies(thumbnailImages: any[], contents: any[]) {
    return contents
      .map(content => {
        // get thumbnail image
        const thumbnailImage = thumbnailImages.find(image => {
          return image.relativeDirectory.includes(content.frontmatter.slug);
        });
        if (!thumbnailImage) {
          // tslint:disable-next-line:no-console
          console.error(`Thumbnail missing for ${content.frontmatter.slug}`);
        }
        return {
          title: content.frontmatter.title,
          description: content.frontmatter.thumbnail.description,
          slug: content.frontmatter.slug,
          childImageSharp: thumbnailImage.childImageSharp
        };
      })
      .map(thumbnail => {
        return (
          <PageComponent.CaseStudyItem
            key={thumbnail.slug}
            to={`/case-studies/${thumbnail.slug}`}
          >
            <PageComponent.CaseStudyImage
              fluid={thumbnail.childImageSharp.fluid}
              title={thumbnail.title}
              alt={thumbnail.title}
            />
            <PageComponent.CaseStudyTitle children={thumbnail.title} />
            <PageComponent.CaseStudyDescription
              children={thumbnail.description}
            />
          </PageComponent.CaseStudyItem>
        );
      });
  }
}

// MARK: Page query

export const pageQuery = graphql`
  query {
    # case studies
    caseStudyThumbnails: allFile(
      filter: { dir: { regex: "/content/*/" }, name: { eq: "thumbnail" } }
    ) {
      edges {
        node {
          relativeDirectory
          childImageSharp {
            fluid(maxHeight: 720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    caseStudyContents: allMarkdownRemark(
      limit: 4
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            thumbnail {
              description
            }
          }
        }
      }
    }
  }
`;
