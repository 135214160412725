import { FlattenInterpolation, ThemeProps } from "styled-components";

import theme from "../../../theme";
import styled, { createGlobalStyle, css } from "../../../theme/styled";

// MARK: Global CSS

// tslint:disable-next-line:no-unused-expression
export const GlobalStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Raleway|Work+Sans');
    
    * {
        box-sizing: border-box;
        margin: 0;
        -webkit-overflow-scrolling: touch;
    }

    html, body {
      ${theme.font.size.base.css};
      background-color: ${theme.color.values.white};
      max-width: 100vw;
      overflow-x: hidden;
    }
`;

// MARK: Components

export const MainLayoutContainer = styled.div<{
  customStyles?: Array<FlattenInterpolation<ThemeProps<any>>>;
}>`
  display: flex;
  flex-direction: column;
  position: relative;

  margin: 0 24px;

  ${({ theme: localTheme }) => localTheme.media.tabletMin`
      min-width: 680px;
      max-width: calc(100vw - 80px);
      margin: 0 auto;
    `};

  ${({ theme: localTheme }) => localTheme.media.tabletLandscapeMin`
      min-width: 936px;
      max-width: calc(100vw - 80px);
    `};

  ${({ theme: localTheme }) => localTheme.media.desktopMin`
      width: calc(100vw - 160px);
      max-width: 1128px;
    `};

  ${({ customStyles }) => customStyles};
`;
