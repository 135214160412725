import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { FlattenInterpolation, ThemeProps } from "styled-components";

// animate on scroll
import AOS from "aos";
import "aos/dist/aos.css";

import theme from "../../../theme";
import { ThemeProvider } from "../../../theme/styled";

import * as Styled from "./styled";

import Footer from "../../footer";
import Navigation from "../../navigation";

interface MainLayoutProps {
  customContainerStyles?: Array<FlattenInterpolation<ThemeProps<any>>>;
  isNavBarFixed?: boolean;
  isFooterAbsolutelyPositioned?: boolean;
}

export default class MainLayout extends React.Component<MainLayoutProps> {
  // MARK: Life cycle

  public componentDidMount() {
    // initialize animate on scroll (AOS)
    AOS.init({
      duration: 1000,
      once: true
    });
  }

  // MARK: Render

  public render() {
    const siteMetaDataQuery = graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `;

    const renderHeadWithData = (data: any) => (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.site.siteMetadata.title}</title>
        <meta name="description" content={data.site.siteMetadata.description} />
      </Helmet>
    );

    return (
      <ThemeProvider theme={theme}>
        <Styled.MainLayoutContainer
          customStyles={this.props.customContainerStyles}
        >
          <Styled.GlobalStyles />

          {
            // > MARK: Head
          }
          <StaticQuery query={siteMetaDataQuery} render={renderHeadWithData} />

          {
            // > MARK: Navigation
          }
          <Navigation isNavBarFixed={this.props.isNavBarFixed} />

          <div data-aos="fade">
            {
              // > MARK: Children
            }
            {this.props.children}

            {
              // > MARK: Footer
            }
            <Footer
              isAbsolutelyPositioned={this.props.isFooterAbsolutelyPositioned}
            />
          </div>
        </Styled.MainLayoutContainer>
      </ThemeProvider>
    );
  }
}
