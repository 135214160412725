import { Link } from "gatsby";
import Img from "gatsby-image";

import styled, { css } from "../../../theme/styled";

import * as Typo from "../../typo";

// MARK: Hero section

export const HeroSection = styled.section`
  width: 100%;
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.media.tabletMin`
    height: calc(100vh - 68px);
  `};

  ${({ theme }) => theme.media.desktopMin`
    height: 100vh;
    align-items: center;
  `};
`;

export const HeroSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -40px;

  ${({ theme }) => theme.media.desktopMin`
    margin-top: -96px;
    align-items: center;
  `};
`;

export const HeroTitle = styled(Typo.H1)`
  ${({ theme }) => theme.media.desktopMin`
    text-align: center;
  `};
`;

export const HeroTitleSpace = styled(Typo.H1.withComponent("span"))`
  display: inline;

  ${({ theme }) => theme.media.desktopMin`
    display: none;  
  `};
`;

export const HeroTitleBreak = styled.br`
  display: none;

  ${({ theme }) => theme.media.tabletLandscapeMin`
    display: inline;
  `};
`;

export const HeroCTAButtonContainer = styled.div`
  margin-top: 29px;

  ${({ theme }) => theme.media.tabletMin`
    margin-top: 53px;
  `};
`;

export const heroActionTitleStyle = css`
  ${({ theme }) => theme.media.tabletMin`
    ${theme.font.size.large.cssWithLineHeightRatio()}
  `};
`;

export const heroActionIconStyle = css`
  ${({ theme }) => theme.media.tabletMin`
    margin-left: 16px;
    height: 24px;
  `};

  ${({ theme }) => theme.media.tabletLandscapeMin`
    margin-left: 16px;
  `};
`;

// MARK: About section

export const AboutSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tabletMin`
    flex-direction: row;
  `};
`;

export const AboutHeading = styled(Typo.H2)`
  ${({ theme }) => theme.media.tabletMin`
    flex: 1;
    margin-right: 12px;
  `};

  ${({ theme }) => theme.media.desktopMin`
    margin-right: 40px;
  `};
`;

export const AboutBodyContainer = styled.div`
  margin-top: 63px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tabletMin`
    margin-top: 0;
    margin-left: 12px;
    flex: 1;
  `};

  ${({ theme }) => theme.media.desktopMin`
    margin-left: 40px;
  `};
`;

export const AboutBody = styled(Typo.BodyRegular)``;

export const AboutCTAButtonContainer = styled.div`
  margin-top: 30px;

  ${({ theme }) => theme.media.tabletLandscapeMin`
      margin-top: 39px;
  `};
`;

// MARK: Case studies section

export const CaseStudiesSection = styled.section`
  margin-top: 195px;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tabletMin`
    margin-top: 320px;
  `};
`;

export const CaseStudiesContainer = styled.div`
  margin-top: 66px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tabletMin`
    margin-top: 94px;
    flex-direction: row;
    flex-wrap: wrap;
  `};
`;

export const CaseStudyItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  text-decoration: none;

  &:first-child {
    margin-top: 0;
  }

  ${({ theme }) => theme.media.tabletMin`
    width: calc(50% - 12px);
    margin-top: 0;
    margin-left: 0px;
    margin-right: 12px;

    &:nth-child(2n) {
      margin-left: 12px;
      margin-right: 0px;
    }

    &:nth-child(n + 3) {
      margin-top: 48px;
    }
  `};

  ${({ theme }) => theme.media.desktopMin`
    width: calc(50% - 24px);
    margin-left: 0px;
    margin-right: 24px;

    &:nth-child(2n) {
      margin-left: 24px;
      margin-right: 0px;
    }
  `};
`;

export const CaseStudyImage = styled(Img)`
  display: block;
  width: 100%;
  height: auto;
  background-color: #eee;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &:hover {
    transform: scale(0.95);
  }
`;

export const CaseStudyImageEmpty = styled.img`
  display: block;
  width: 100%;
  padding-top: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.04);

  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &:hover {
    transform: scale(0.95);
  }
`;

export const CaseStudyTitle = styled(Typo.H3)`
  margin-top: 37px;
  text-align: center;
`;

export const CaseStudyDescription = styled(Typo.BodyRegular)`
  margin-top: 4px;
  text-align: center;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.values.grey};

  ${({ theme }) => theme.media.tabletLandscapeMin`
      margin-top: 8px;
  `};
`;

// MARK: Contact section

export const ContactSection = styled.section`
  margin-top: 40px;
  width: 100%;
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContactSectionContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactEmail = styled.a``;

export const ContactCTAButtonContainer = styled.div`
  margin-top: 33px;

  ${({ theme }) => theme.media.tabletMin`
    margin-top: 52px;
  `};
`;
